import { h } from "preact";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export default function HeaderMenu() {
  const { t, i18n } = useTranslation();

  const routes = [
    { title: "headerNav.institutional", path: "/institucional" },
    { title: "headerNav.contacts", path: "/contactos" },
  ];

  const renderMenuItem = (item) => {
    return (
      <MenuItem>
        <Link href={item.path}>{t(item.title)}</Link>
      </MenuItem>
    );
  };

  return <Wrapper>{routes.map(renderMenuItem)}</Wrapper>;
}

const Wrapper = styled.ul`
  display: flex;
  height: 100%;
  margin: 0 2rem 0 2rem;
  padding: 0;
  align-items: center;
`;

const MenuItem = styled.li`
  padding: 0 2rem;
  list-style: none;
`;

const Link = styled.a`
  color: #fff;

  &:hover {
    color: #fff;
    text-decoration: none;
  }
`;
