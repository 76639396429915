// PRD
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://services.qmob.pt';

export const FILES_BASE_URL = process.env.REACT_APP_FILES_BASE_URL || 'https://storage.googleapis.com/wgs-prd/qmob/';

export const corsProxy = ''; // 'https://cors.ripka.workers.dev/?';

export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoid2dzLXN3IiwiYSI6ImNsNjZlcnlreDBmZ2Yzb25vbnR1eGk1NmgifQ.H-yEU9F5JwJoGxbgBcCIOQ';

// QA
//export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://services.porto.qa.wegoshare.com';

//export const FILES_BASE_URL = process.env.REACT_APP_FILES_BASE_URL || 'https://storage.googleapis.com/wgs-qa/qmob/';

//export const corsProxy = ''; // 'https://cors.ripka.workers.dev/?';

//export const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoid2dzLXN3IiwiYSI6ImNsNjZlcnlreDBmZ2Yzb25vbnR1eGk1NmgifQ.H-yEU9F5JwJoGxbgBcCIOQ';