import { h } from "preact";
import { useTranslation } from 'react-i18next';

import cidades from "../images/citys-logos.png";
import fender from "../images/barra-fender.png";

export default function Footer() {
  const { t, i18n } = useTranslation();

  return (
    <div class="container-fuid p-3 footer-container bg-dark">
      <div class="row justify-content-md-between justify-content-center">
        <div class="col-auto my-auto fender-logos">
          <img src={fender}/>
        </div>
        <div class="col-auto my-auto text-center d-none d-md-block">
            <p class="footer-copy">{t('footer.allRights')}</p>
            <p class="footer-copy-dev">
              {t('footer.devBy')} <a href="https://wegoshare.com/" target="_blank">Wegoshare, Lda.</a><span class="footer-copy-dev-hidden"> v0.2</span>
            </p>
        </div>
        <div class="col-auto my-auto d-none d-md-block mun-logos">
          <img src={cidades} />
        </div>
      </div>
    </div>
  );
}
