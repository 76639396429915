import toast from "react-hot-toast";
import { h } from "preact";
import styled from "styled-components";
import { useState, useRef, useEffect, useMemo } from "preact/hooks";
import { useTranslation } from "react-i18next";
import moment from "moment";

import Notification from "./Notification";
import loadNotifications from "../utils/loadNotifications";

export default function GlobalNotifications() {
  const { t, i18n } = useTranslation();
  const [notifications, setNotifications] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded) {
      return;
    }

    loadNotifications(i18n.resolvedLanguage, "priority", "DESC").then((response) => {
      const { results } = response;

      const newNotifications = results.filter((n) => {
        const lSValue = localStorage.getItem(`notif-${n.id}`);

        if (!lSValue) {
          return true;
        }

        return moment().unix() >= +lSValue;
      });
      setNotifications(newNotifications);
      setLoaded(true);
    });
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    notifications.forEach((notification) => {
      toast.custom(
        (t) => (
          <Notification
            t={t}
            title={notification.title}
            id={notification.id}
            text={notification.text}
            priority={notification.priority}
          />
        ),
        { duration: 10 * 60000 }
      );
    });
  }, [notifications]);

  return "";
}
