import { h } from "preact";
import styled from "styled-components";

export default function ContentPage({ title, content }) {
  return (
    <Wrapper className="container p-lg-5">
      <Title className="display-5">{title}</Title>
      <Content>{content}</Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-bottom: 100px;

  @media (max-width: 1200px) {
    margin-top: 100px;
  }
`;

const Title = styled.h1`
  margin-bottom: 50px;
`;
const Content = styled.div``;
