import mapboxgl from 'mapbox-gl';
import checkGeolocationSupport from '../utils/checkGeolocationSupport';
import compassHeading from '../utils/compassHeading';

// A fork of Mapbox GL JS's GeolocateControl, simplified + compass support
export default class GeolocateControl {
  _watching = false;
  _locking = false;
  _setup = false;
  _currentLocation = null;
  _buttonClicked = false;
  constructor(options) {
    this.options = Object.assign(
      {
        offset: [0, 0],
        onClick: () => {},
      },
      options,
    );
  }
  onAdd(map) {
    this._map = map;
    const container = document.createElement('div');
    container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    this._container = container;
    checkGeolocationSupport(this._setupUI);
    return this._container;
  }
  _setupUI = (supported) => {
    if (!supported) {
      console.warn('Geolocation support is not available.');
    }

    const button = document.createElement('button');
    button.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-custom-geolocate';
    button.type = 'button';
    button.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="21.265" height="21.285" viewBox="0 0 21.265 21.285"><g transform="translate(-11 -4)"><path d="M30.65,11.075,11.409,19.058a.525.525,0,0,0,.078.988l8.867,2,2,8.867a.5.5,0,0,0,.962.078l8.009-19.241a.519.519,0,0,0-.676-.676ZM13.281,19.4,28.648,13l-8.035,8.035Zm9.7,9.7-1.664-7.332,8.035-8.035Z" transform="translate(-0.596 -6.531)"/><path d="M30.854,10.531a1,1,0,0,1,.815.428,1.04,1.04,0,0,1,.129.958l-.01.026L23.781,31.18a1,1,0,0,1-1.914-.159l-1.932-8.555L11.36,20.529a1.025,1.025,0,0,1-.141-1.934l19.236-7.98A1,1,0,0,1,30.854,10.531Zm0,1.046a.042.042,0,0,0-.007-.044l-.008,0L11.6,19.52a.042.042,0,0,0,0,.038l.009,0,9.162,2.069,2.073,9.182v0h0l0,0,0-.011Zm-2.4.961.546.815-8.229,8.229L11.633,19.54Zm-8,7.948,6.055-6.055-11.58,4.82ZM29,13.374l.815.545L22.84,30.737,20.767,21.6ZM23.12,27.452,27.925,15.86,21.864,21.92Z" transform="translate(-0.596 -6.531)"/></g></svg>`;
    button.addEventListener('click', this._clickButton, false);
    this._button = button;
    this._container.appendChild(this._button);

    const dot = document.createElement('div');
    dot.innerHTML = `<div class="user-location-dot"></div>
      <div class="user-location-compass" hidden></div>
      <div class="user-location-accuracy"></div>`;
    dot.className = 'user-location';
    this._dot = new mapboxgl.Marker(dot);
    const dotElement = this._dot.getElement();
    this._compass = dotElement.querySelector('.user-location-compass');
    // this._accuracy = dotElement.querySelector('.user-location-accuracy');

    this._map.on('movestart', (e) => {
      if (!e.geolocateSource && this._locking) {
        this._locking = false;
        this._updateButtonState(null);
      }
    });

    this._setup = true;

    if (supported === 'granted') this._clickButton(null, false);
  };
  _updateButtonState = (state) => {
    const { classList } = this._button;
    classList.remove('loading');
    classList.remove('active');
    if (state) classList.add(state);
  };
  _flyToCurrentLocation = () => {
    const map = this._map;
    const { _currentLocation: center } = this;
    const { offset: _offset } = this.options;
    const offset = typeof _offset === 'function' ? _offset() : _offset;
    const { x, y } = map.project(center);
    const { offsetWidth, offsetHeight, offsetLeft, offsetTop } =
      map.getContainer();
    const margin = Math.max(offsetWidth, offsetHeight);
    const withinBounds =
      x > offsetLeft - margin &&
      x < offsetWidth + margin &&
      y > offsetTop - margin &&
      y < offsetHeight + margin;
    // console.log(center, x, y, offsetLeft, offsetTop, offsetWidth, offsetHeight);
    const eventData = {
      geolocateSource: true,
    };
    if (withinBounds) {
      if (map.getZoom() < 14) {
        map.easeTo(
          {
            center,
            zoom: 16,
            duration: 300,
            offset,
            animate: false,
          },
          eventData,
        );
      } else {
        map.flyTo(
          {
            center,
            zoom: 16,
            speed: 1.5,
            duration: 2000,
            offset,
          },
          eventData,
        );
      }
    } else {
      map.easeTo(
        {
          center,
          zoom: 16,
          duration: 300,
          offset,
          animate: false,
        },
        eventData,
      );
    }
  };
  _setHeading = (e) => {
    if (!this._watching) return;
    if (!e || e.alpha === null) return;
    this._compass.hidden = false;
    const heading =
      e.compassHeading ||
      e.webkitCompassHeading ||
      compassHeading(e.alpha, e.beta, e.gamma);
    // -60deg rotateX is for *tilting* the compass "box" to look like a trapezoid
    this._compass.style.transform = `rotate(${Math.round(
      heading,
    )}deg) rotateX(-60deg)`;
  };
  _clickButton = (e, locking = true) => {

    locking = true;

    if (e) e.preventDefault();
    if (!this._setup) return;
    const { onClick } = this.options;

    if (this._watching) {
      this._updateButtonState('active');
      this._flyToCurrentLocation();
      this._locking = true;
      onClick(this._currentLocation);
    } else {
      this._updateButtonState('loading');
      this._buttonClicked = true;
      this._locking = locking;
      let deviceorientation;

      this._watching = navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          if (`${[latitude, longitude]}` === `${this._currentLocation}`) return; // No idea why

          // console.log({ latitude, longitude });
          this._currentLocation = [longitude, latitude];
          this._dot.setLngLat(this._currentLocation);
          if (!this._dot._addedToMap) {
            this._dot.addTo(this._map);
            this._dot._addedToMap = true;
          }

          if (this._locking) {
            this._updateButtonState('active');
            this._flyToCurrentLocation();
          } else {
            this._updateButtonState(null);
          }

          if (this._buttonClicked) {
            // Differentiate between initiated from button click or watchPosition subsequent runs
            this._buttonClicked = false;
            onClick(this._currentLocation);
          }
          this._watching = true;
        },
        (e) => {
          this._locking = false;
          this._watching = false;
          this._buttonClicked = false;

          this._updateButtonState(null);
          navigator.geolocation.clearWatch(this._watching);
          if (deviceorientation) {
            window.removeEventListener(deviceorientation, this._setHeading);
          }

          console.warn(e);
          if (e.code === 1) {
            // PERMISSION_DENIED
            alert(
              'Looks like location tracking is blocked on your browser. Please enable it in the settings to use this feature.',
            );
          } else {
            // Retry again
            this._clickButton();
          }
        },
        {
          enableHighAccuracy: true,
          timeout: 60 * 1000, // 1min
          maximumAge: 1000, // 1s
        },
      );

      if (window.DeviceOrientationEvent) {
        // https://developers.google.com/web/updates/2016/03/device-orientation-changes
        // https://stackoverflow.com/a/47870694/20838
        if (location.hostname === 'localhost') {
          // Stupid bug with Chrome
          // `ondeviceorientationabsolute` is true but always return empty values
          deviceorientation = 'deviceorientation';
        } else {
          deviceorientation =
            'ondeviceorientationabsolute' in window
              ? 'deviceorientationabsolute'
              : 'deviceorientation';
        }
        window.addEventListener(deviceorientation, this._setHeading, false);
      }
    }
  };
}
