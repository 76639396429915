import { API_BASE_URL } from "../config";

const loadQrCodeData = (hash) => {
  return fetch(`${API_BASE_URL}/v3/busdata/qrcode/detail`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      hash,
    }),
  })
    .then((r) => r.json())
}

export default loadQrCodeData;
