import { h } from "preact";
import { useMemo } from 'preact/hooks';
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export default function AgenciesPopover({ agenciesAvailable, onChange }) {
  const { t } = useTranslation();

  const agenciesAvailableByGroup = useMemo(() => {
    const groups = agenciesAvailable.reduce((acc, cur) => {
      if (acc.includes(cur.group)) {
        return acc;
      }

      return [...acc, cur.group];
    }, []);

    return groups.map(group => ({
      name: group,
      agencies: agenciesAvailable.filter(a => a.group === group),
    }));
  }, [agenciesAvailable]);

  return (
    <div id="agencies-popover" className="agency-popover">
      <div className="list-container">
        {agenciesAvailableByGroup.map((group) => (
          <Group key={group}>
            <GroupName>{t(`agencies.${group.name}`)}</GroupName>
            <GroupItems>
              {group.agencies.map((item, index) => (
                <GroupItem key={index}>
                  <input
                    class="form-check-input"
                    defaultChecked="true"
                    value={item.agency}
                    ref={item.agency}
                    type="checkbox"
                    onChange={onChange}
                  />
                  <span>{item.name}</span>
                </GroupItem>
              ))}
            </GroupItems>
          </Group>
        ))}
      </div>
    </div>
  );
}

const Group = styled.div``;

const GroupName = styled.div`
  font-weight: bold;
`;

const GroupItems = styled.div`
    margin-left: 24px;
`;
const GroupItem = styled.div`
    padding: 4px 0;
`;