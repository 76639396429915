const loadGeocoderButtonOnMap = () => {
  const mapSearhElem = document.createElement('div');
  mapSearhElem.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
  mapSearhElem.id = 'map-search';
  mapSearhElem.innerHTML = '<button type="button" class="mapboxgl-ctrl-icon mapboxgl-search-switcher"></button>';

  const onClick = (e) => {
    const geocoderEl = document.getElementById('geocoder');
    const mapEl = document.getElementById('map');

    geocoderEl.style.top = `${mapEl.offsetTop + 10}px`;
    geocoderEl.style.left = `${mapEl.offsetWidth - 16}px`;

    const styles = window.getComputedStyle(geocoderEl);
    if (styles.display === 'none') {
      geocoderEl.style.display = "block";
    } else {
      geocoderEl.style.display = "none";
    }
  };
  mapSearhElem.addEventListener("click", onClick, false);

  document.getElementsByClassName('mapboxgl-ctrl-top-right')[0]
    .prepend(mapSearhElem);
};

export default loadGeocoderButtonOnMap;
