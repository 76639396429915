import { h } from "preact";
import Parser from "html-react-parser";
import { useTranslation } from "react-i18next";

import {
  LANGUAGE_EN_CODE,
  LANGUAGE_ES_CODE,
  LANGUAGE_PT_CODE,
} from "../utils/constants";
import useContentfulContent from "../hooks/useContentfulContent";
import ContentPage from "../components/ContentPage";

const PAGE_ID_BY_LANG = {
  [LANGUAGE_PT_CODE]: "4ZYc0FyhFAHkdNUzesWkjU",
  [LANGUAGE_EN_CODE]: "5oj7AbHoyWH0fnbN0rpmEw",
  [LANGUAGE_ES_CODE]: "19PWBfIzoH7ZPFYyM9O4Id",
};

export default function Institucional() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.resolvedLanguage;
  const pageId = PAGE_ID_BY_LANG[currentLang];

  const { title, content } = useContentfulContent(pageId);

  return (
    <ContentPage title={title} content={Parser(content)} />
  );
}