import { h, Fragment } from "preact";
import Parser from "html-react-parser";
import { useTranslation } from "react-i18next";

import {
  LANGUAGE_EN_CODE,
  LANGUAGE_ES_CODE,
  LANGUAGE_PT_CODE,
} from "../utils/constants";
import useContentfulContent from "../hooks/useContentfulContent";
import ContentPage from "../components/ContentPage";
import ContactForm from "../components/ContactForm";

const PAGE_ID_BY_LANG = {
  [LANGUAGE_PT_CODE]: "3eUsQfkdQdZrMKbdEZbOUj",
  [LANGUAGE_EN_CODE]: "2br17kCNWCZVHWcWZAplYX",
  [LANGUAGE_ES_CODE]: "3bLQAY8NcJtfumsgWUt9Cn",
};

export default function Contacts() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.resolvedLanguage;
  const pageId = PAGE_ID_BY_LANG[currentLang];

  const { title, content } = useContentfulContent(pageId);

  const _renderContent = () => (
    <Fragment>
      {Parser(content)}
      
    </Fragment>
  )

  return (
    <ContentPage title={title} content={_renderContent()} />
  );
}