import Cookies from 'js-cookie';

const HOME = { page: 'home' };

export default () => {
  const locationHash = location.hash.replace(/^#/, '') || '/';

  const [path, id] = locationHash.split('?');
  
  // example: http://localhost:8888/#/qrcode/343ffa4f-efb8-4c43-9416-26384602ffe2
  const isQrCodePath = path.match(/(qrcode)s?\/([^\/]+)\/?([^\/]+)?/);
  const requestId = Cookies.get('url.id');

  if (id !== requestId && !isQrCodePath) {
    location.hash = '/';
    return HOME;
  }

  if (path === '/') return HOME;
  let [_, page, value, subpage] =
    path.match(/(qrcode|service|stop|between)s?\/([^\/]+)\/?([^\/]+)?/) || [];
  return { page, value, path, subpage };
};
