import { API_BASE_URL } from "../config";

const loadNotifications = (language, orderBy, sortBy, stopId) => {
  return fetch(`${API_BASE_URL}/v3/busdata/announcement`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      offset: 0,
      limit: 20,
      language,
      ...(stopId ? { stop_id: stopId } : {}),
      ...(orderBy ? { order_by: orderBy } : {}),
      ...(sortBy ? { sort_by: sortBy } : {})
    }),
  })
    .then((r) => r.json())
}

export default loadNotifications;
