import './i18n';
import './error-tracking';
import "mapbox-gl-style-switcher/styles.css";
import { useEffect } from 'preact/hooks';
import Cookies from 'js-cookie';

import { h, render, Fragment } from 'preact';
import { createBrowserRouter, RouterProvider} from "react-router-dom";
import { Toaster } from 'react-hot-toast';
import Header from './components/Header';
import Footer from './components/Footer';
import Map from './routes/Map';
import Institucional from './routes/Institucional';
import Contacts from './routes/Contacts';
import iconSVGPath from './images/logo_black.png';

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Map />,
    },
    {
      path: "/institucional",
      element: <Institucional />,
    },
    {
      path: "/contactos",
      element: <Contacts />,
    },
  ]);

  useEffect(() => {
    Cookies.set('url.id', Math.random().toString(36).slice(2, 7));

    () => {
      Cookies.remove('url.id')
    }
  }, []);

  return (
    <>
      <Header />
      <RouterProvider router={router} />
      <Footer />
      <Toaster
        position="top-center"
        reverseOrder={false}
        containerClassName="Toast-Notification"
      />
    </>
  );
};

render(<App />, document.getElementById('app'));

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register(
      new URL('../service-worker.js', import.meta.url),
      { type: 'module' },
    );
  });
}

if (
  matchMedia('(display-mode: standalone)').matches ||
  'standalone' in navigator
) {
  gtag('event', 'pwa_load', {
    event_category: 'PWA',
    event_label: 'standalone',
    value: true,
    non_interaction: true,
  });
}

if (window.navigator.standalone) {
  document.body.classList.add('standalone');

  document.addEventListener('focusout', () => {
    if (_map) _map.resize();
  });

  // Enable CSS active states
  document.addEventListener('touchstart', () => {}, false);
}

const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') !== -1;
if (isSafari && !window.navigator.standalone) {
  setTimeout(function () {
    const ratio = window.devicePixelRatio;
    const canvas = document.createElement('canvas');
    const w = (canvas.width = window.screen.width * ratio);
    const h = (canvas.height = window.screen.height * ratio);
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = '#F9F5ED';
    ctx.fillRect(0, 0, w, h);
    const icon = new Image();
    icon.onload = () => {
      const aspectRatio = icon.width / icon.height;
      icon.width = w / 2;
      icon.height = w / 2 / aspectRatio;
      ctx.drawImage(
        icon,
        (w - icon.width) / 2,
        (h - icon.height) / 2,
        icon.width,
        icon.height,
      );
      document.head.insertAdjacentHTML(
        'beforeend',
        `<link rel="apple-touch-startup-image" href="${canvas.toDataURL()}">`,
      );
    };
    icon.src = iconSVGPath;
  }, 5000);
}
