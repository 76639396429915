const loadAgenciesButtonOnMap = () => {
  const agenciesButtonElem = document.createElement('div');
  agenciesButtonElem.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
  agenciesButtonElem.id = 'map-agencies';
  agenciesButtonElem.innerHTML = '<button type="button" class="mapboxgl-ctrl-icon mapboxgl-agencies-switcher"></button>';

  const onClick = (e) => {
    const agenciesEl = document.getElementById('agencies-popover');
    const mapEl = document.getElementById('map');

    agenciesEl.style.top = `${mapEl.offsetTop + 60}px`;
    agenciesEl.style.left = `12px`;

    const styles = window.getComputedStyle(agenciesEl);
    if (styles.display === 'none') {
      agenciesEl.style.display = "block";
    } else {
      agenciesEl.style.display = "none";
    }
  };
  agenciesButtonElem.addEventListener("click", onClick, false);

  document.getElementsByClassName('mapboxgl-ctrl-top-left')[0]
    .prepend(agenciesButtonElem);
}

export default loadAgenciesButtonOnMap;
