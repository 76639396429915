{
  "common": {
    "cancel": "Cancel",
    "live": "Live",
    "noResults": "No results.",
    "close": "Close",
    "loadind": "Loading"
  },
  "glossary": {
    "busArrivals": "Bus arrivals",
    "passingRoutes": "Passing routes",
    "popOut": "Pop out",
    "seatsAvailable": "Seats available",
    "standingAvailable": "Standing available",
    "limitedStanding": "Limited standing",
    "arriving": "Arr",
    "arrivingMinutes": "{{count}} m",
    "arrivingMinutesWithDelay": "{{count}} m (+{{delay}} m)",
    "arrivingMinutes_plural": "{{count}} m",
    "multiRouteMode": "Multi-route mode",
    "nRoutes": "{{count}} route",
    "nRoutes_plural": "{{count}} routes",
    "nStops": "{{count}} stop",
    "nStops_plural": "{{count}} stops",
    "nServices": "{{count}} service",
    "nServices_plural": "{{count}} services",
    "weekdaysShort": "WD",
    "weekdays": "Weekdays",
    "saturdaysShort": "SAT",
    "saturdays": "Saturdays",
    "sundaysPublicHolidaysShort": "SUN",
    "sundaysPublicHolidays": "Sunday & Public Holidays",
    "service": "Service",
    "firstBus": "First Bus",
    "lastBus": "Last Bus",
    "time-na": "Estimated",
    "time-ontime": "On time",
    "time-delay": "Slight delay ({{count}} m)",
    "time-delayplus": "Delayed ({{count}} m)"
  },
  "app": {
    "name": "QMob - Quadrilátero",
    "title": "$t(app.name)",
    "shortDescription": "QMob - Quadrilátero",
    "description": "Check the stops and routes on the map for all Quadrilátero Urbano services, in the municipalities of Barcelos, Braga, Guimarães and Vila Nova de Famalicão, and in the Inter-municipal Communities of Ave and Cávado."
  },
  "about": {
    "disclaimerCopyright": "",
    "helpTranslations": "",
    "sisterSites": "",
    "liking": "",
    "treatCoffee": "",
    "explore": ""
  },
  "headerNav": {
    "institutional": "Institutional",
    "contacts": "Contacts"
  },
  "search": {
    "placeholder": "Search for bus service or stop"
  },
  "service": {
    "title": "QMob - {{serviceNumber}}: {{serviceName}}",
    "titleMultiple": "Bus services: {{serviceNumbersNames}}",
    "oppositeLegend": "Bus stops with opposite direction of services",
    "remove": "Remove"
  },
  "stop": {
    "title": "QMob - {{stopName}}",
    "titleRoutes": "Routes passing bus stop {{stopName}}",
    "firstLastBus": "First/last bus",
    "liveBusTrack": "{{count}} bus now on track",
    "liveBusTrack_plural": "{{count}} buses now on track."
  },
  "passingRoutes": {
    "passingRoutes": "{{count}} passing route",
    "passingRoutes_plural": "{{count}} passing routes"
  },
  "multiRoute": {
    "addRoute": "Add another bus route",
    "showingServices": "Showing {{count}} service",
    "showingServices_plural": "Showing {{count}} services",
    "intersectingStops": "{{count}} intersecting stop",
    "intersectingStops_plural": "{{count}} intersecting stops"
  },
  "arrivals": {
    "invalidBusStopCode": "Invalid bus stop code.",
    "preHeading": "Bus arrival times",
    "onTime": "On time:",
    "inDelay": "Slight delay:",
    "inDelayPlus": "Delayed:",
    "title": "QMob - Bus arrival times",
    "titleStop": "Bus arrival times for {{stopName}}",
    "wheelchairDisclaimer": "<0/> All public buses in revenue service are <2>wheelchair-accessible</2>. The ones that are not accesssible will be marked with this icon <4/>",
    "noArrivalTimeAvailable": "No arrival times available.",
    "plataformNumber": "Platform"
  },
  "firstLast": {
    "title": "QMob - Approximate first & last bus arrival times for  {{stopName}}",
    "busStopCodeNotFound": "Bus stop code not found.",
    "preHeading": "Approximate first & last bus arrival times"
  },
  "agencies": {
    "municipios": "Counties",
    "cim": "Inter municipal communities",
    "privados": "Private"
  },
  "footer": {
    "allRights": "© QMob Quadrilátero - All rights reserved.",
    "devBy": "Developed by"
  }
}
