import * as contentful from "contentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { useEffect, useState } from "preact/hooks";

const CONTENTFUL_SPACE_ID = "xe9dt76527yi";
const CONTENTFUL_ACCESS_TOKEN = "qTIcHiaT1WfeFXwl7eG_JOfFdRMGCiBVP1HWOAzPZ-w";

// https://www.contentful.com/developers/docs/javascript/tutorials/rendering-contentful-rich-text-with-javascript/

const useContentfulContent = (pageId) => {
  const client = contentful.createClient({
    // This is the space ID. A space is like a project folder in Contentful terms
    space: CONTENTFUL_SPACE_ID,
    // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
    accessToken: CONTENTFUL_ACCESS_TOKEN,
  });

  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    if (!pageId) return;

    client
      .getEntry(pageId)
      .then((entry) => {
        setTitle(entry.fields.title);
        setContent(documentToHtmlString(entry.fields.content));
      })
      .catch((err) => console.log(err));
  }, [pageId]);

  return {
    title,
    content,
  };
};

export default useContentfulContent;
