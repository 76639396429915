import { h } from "preact";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import moment from "moment";

import lowwarning from "../images/low_warning.svg";
import mediumwarning from "../images/medium_warning.svg";
import highwarning from "../images/high_warning.svg";

const mapPriorityIcon = {
  'HIGH': highwarning,
  'MEDIUM': mediumwarning,
  'LOW': lowwarning,
}

export default function Notification({ t: toastObject, id, title, text, priority }) {
  const { t } = useTranslation();

  const handleCloseNotification = () => {
    toast.dismiss(toastObject.id);

    localStorage.setItem(`notif-${id}`, moment().add(1, 'day').unix());
  };

  return (
    <Wrapper>
      <IconContainer>
        <img src={mapPriorityIcon[priority] || mediumwarning} />
      </IconContainer>
      <MessageContainer>
        <Title>{title}</Title>
        <Message>{text}</Message>
      </MessageContainer>
      <ButtonContainer>
        <Button onClick={handleCloseNotification} type="button">{t("common.close")}</Button>
      </ButtonContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-width: 650px;
  min-width: 400px;
  background-color: #fff;
  border-radius: 5px;
  padding: 12px;
  font-size: 12px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
  display: grid;
  grid-template-columns: 50px 500px 100px;
  margin-right: 400px;
  @media (max-width: 1200px) {
    margin-right: 0;
    grid-template-columns: 50px auto auto;
    min-width: 0;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 50px;
    height: 50px;
  }
`;

const MessageContainer = styled.div`
  padding-left:12px;
  padding-right:12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 6px;
`;

const Message = styled.div``;

const Button = styled.button`
  background: transparent;
  border: none;
  outline: none;
  font-weight: bold;
`;
