{
  "common": {
    "cancel": "Cancelar",
    "live": "En directo",
    "noResults": "No hay resultados.",
    "close": "Fechar",
    "loadind": "cargando"
  },
  "glossary": {
    "busArrivals": "Llegadas",
    "passingRoutes": "Rutas",
    "popOut": "Muestra",
    "seatsAvailable": "Asientos",
    "standingAvailable": "Lugares de pie",
    "limitedStanding": "Capacidad limitada",
    "arriving": "a llegar",
    "arrivingMinutes": "{{count}} m",
    "arrivingMinutesWithDelay": "{{count}} m (+{{delay}} m)",
    "arrivingMinutes_plural": "{{count}} m",
    "multiRouteMode": "Modo multirruta",
    "nRoutes": "{{count}} ruta",
    "nRoutes_plural": "{{count}} rutas",
    "nStops": "{{count}} parada",
    "nStops_plural": "{{count}} paradas",
    "nServices": "{{count}} servicio",
    "nServices_plural": "{{count}} servicios",
    "weekdaysShort": "DS",
    "weekdays": "Días de semana",
    "saturdaysShort": "Sáb",
    "saturdays": "Sábados",
    "sundaysPublicHolidaysShort": "Dom",
    "sundaysPublicHolidays": "Domingos y festivos",
    "service": "Servicio",
    "firstBus": "Primer autobús",
    "lastBus": "Último autobús",
    "time-na": "Estimado",
    "time-ontime": "A tiempo",
    "time-delay": "Ligero retraso ({{count}} m)",
    "time-delayplus": "Retrasado ({{count}} m)"
  },
  "app": {
    "name": "QMob - Quadrilátero",
    "title": "$t(app.name)",
    "shortDescription": "QMob - Quadrilátero",
    "description": "Vea las paradas y rutas en el mapa de todos los servicios de Quadrilátero Urbano, en los municipios de Barcelos, Braga, Guimarães y Vila Nova de Famalicão, y en las Comunidades Intermunicipales de Ave y Cávado."
  },
  "about": {
    "disclaimerCopyright": "",
    "helpTranslations": "",
    "sisterSites": "",
    "liking": "",
    "treatCoffee": "",
    "explore": ""
  },
  "headerNav": {
    "institutional": "Institutional",
    "contacts": "Contacts"
  },
  "search": {
    "placeholder": "Búsqueda"
  },
  "service": {
    "title": "QMob - {{serviceNumber}}: {{serviceName}}",
    "titleMultiple": "Autobúses: {{serviceNumbersNames}}",
    "oppositeLegend": "Paradas de autobús con sentido contrario al servicio",
    "remove": "Retirar"
  },
  "stop": {
    "title": "QMob - {{stopName}}",
    "titleRoutes": "Rutas de parada {{stopName}}",
    "firstLastBus": "Primer/último autobús",
    "liveBusTrack": "{{count}} autobús ahora en camino",
    "liveBusTrack_plural": "{{count}} autobúses ahora en camino"
  },
  "passingRoutes": {
    "passingRoutes": "{{count}} ruta",
    "passingRoutes_plural": "{{count}} rutas"
  },
  "multiRoute": {
    "addRoute": "Añadir ruta",
    "showingServices": "Mostrando {{count}} servicio",
    "showingServices_plural": "Mostrando {{count}} servicios",
    "intersectingStops": "{{count}} parada de intersección",
    "intersectingStops_plural": "{{count}} paradas de intersección"
  },
  "arrivals": {
    "invalidBusStopCode": "Codigo invalido",
    "preHeading": "Tiempos de llegada",
    "onTime": "A tiempo:",
    "time-ontime": "A tiempo",
    "inDelay": "Ligero retraso:",
    "inDelayPlus": "Retrasado:",
    "title": "QMob - Tiempos de llegada",
    "titleStop": "Tiempos de llegada para {{stopName}}",
    "wheelchairDisclaimer": "",
    "noArrivalTimeAvailable": "No arrival times available.",
    "plataformNumber": "Plataforma"
  },
  "firstLast": {
    "title": "QMob - Horas aproximadas de llegada del primer y último autobús a {{stopName}}",
    "busStopCodeNotFound": "Parada no encontrada",
    "preHeading": "Horas aproximadas de llegada del primer y último autobús"
  },
  "agencies": {
    "municipios": "Condados",
    "cim": "Comunidades intermunicipales",
    "privados": "Privados"
  },
  "footer": {
    "allRights": "© QMob Quadrilátero - Todos los derechos reservados.",
    "devBy": "Desarrollado por"
  }
}
