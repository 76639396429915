const hashCode = (s) => {
  let h = 0;
  let l = s.length;
  let i = 0;

  if ( l > 0 )
    while (i < l)
      h = (h << 5) - h + s.charCodeAt(i++) | 0;
      
  return h;
};

export const encode = (id) => {
  // const encoded = parseInt(
  //   id.replace(/\w/gi, (c) => '' + c.charCodeAt()),
  //   10,
  // );

  return hashCode(id);
};

export const decode = (number) => {
  // const decoded = String.fromCharCode(...('' + number).match(/\w\w/gi))

  return number;
};
