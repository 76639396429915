{
  "common": {
    "cancel": "Cancelar",
    "live": "Em directo",
    "noResults": "Sem resultados.",
    "close": "Fechar",
    "loadind": "A carregar"
  },
  "glossary": {
    "busArrivals": "Chegadas",
    "passingRoutes": "Rotas",
    "popOut": "Mostrar",
    "seatsAvailable": "Lugares sentados",
    "standingAvailable": "Lugares em pé",
    "limitedStanding": "Lotação limitada",
    "arriving": "A chegar",
    "arrivingMinutes": "{{count}} m",
    "arrivingMinutesWithDelay": "{{count}} m (+{{delay}} m)",
    "arrivingMinutes_plural": "{{count}} m",
    "multiRouteMode": "Modo multi-rotas",
    "nRoutes": "{{count}} rota",
    "nRoutes_plural": "{{count}} rotas",
    "nStops": "{{count}} paragen",
    "nStops_plural": "{{count}} paragens",
    "nServices": "{{count}} serviço",
    "nServices_plural": "{{count}} serviços",
    "weekdaysShort": "DS",
    "weekdays": "Dias da semana",
    "saturdaysShort": "Sáb",
    "saturdays": "Sábados",
    "sundaysPublicHolidaysShort": "Dom",
    "sundaysPublicHolidays": "Domingos e feriados",
    "service": "Serviço",
    "firstBus": "Primeiro autocarro",
    "lastBus": "Último autocarro",
    "time-na": "Estimado",
    "time-ontime": "No horário",
    "time-delay": "Ligeiro atraso ({{count}} m)",
    "time-delayplus": "Atrasado ({{count}} m)"
  },
  "app": {
    "name": "QMob - Quadrilátero",
    "title": "$t(app.name)",
    "shortDescription": "QMob - Quadrilátero",
    "description": "Veja as paragens e rotas no mapa para todos os serviços do Quadrilátero Urbano, nos municípios de Barcelos, Braga, Guimarães e Vila Nova de Famalicão, e nas Comunidades Inter-municipais do Ave e do Cávado."
  },
  "about": {
    "disclaimerCopyright": "",
    "helpTranslations": "",
    "sisterSites": "",
    "liking": "",
    "treatCoffee": "",
    "explore": ""
  },
  "headerNav": {
    "institutional": "Institucional",
    "contacts": "Contactos"
  },
  "search": {
    "placeholder": "Pesquisar"
  },
  "service": {
    "title": "QMob - {{serviceNumber}}: {{serviceName}}",
    "titleMultiple": "Autocarros: {{serviceNumbersNames}}",
    "oppositeLegend": "Paragens de autocarro com serviço em sentido contrário",
    "remove": "Remover"
  },
  "stop": {
    "title": "QMob - {{stopName}}",
    "titleRoutes": "Rotas da paragem {{stopName}}",
    "firstLastBus": "Primeiro/último autocarro",
    "liveBusTrack": "{{count}} autocarro na rota",
    "liveBusTrack_plural": "{{count}} autocarros na rota"
  },
  "passingRoutes": {
    "passingRoutes": "{{count}} rota",
    "passingRoutes_plural": "{{count}} rotas"
  },
  "multiRoute": {
    "addRoute": "Adicionar rota",
    "showingServices": "A mostrar {{count}} serviço",
    "showingServices_plural": "A mostrar {{count}} serviços",
    "intersectingStops": "{{count}} paragem de interseção",
    "intersectingStops_plural": "{{count}} paragens de interseção"
  },
  "arrivals": {
    "invalidBusStopCode": "Código inválido",
    "preHeading": "Tempos de chegada",
    "onTime": "No horário:",
    "inDelay": "Ligeiro atraso:",
    "inDelayPlus": "Atrasado:",
    "title": "QMob - Tempos de chegada",
    "titleStop": "Tempos de chegada para {{stopName}}",
    "wheelchairDisclaimer": "",
    "noArrivalTimeAvailable": "Sem Tempos de chegada disponiveis.",
    "plataformNumber": "Cais"
  },
  "firstLast": {
    "title": "QMob - Horários aproximados de chegada do primeiro e último autocarro para {{stopName}}",
    "busStopCodeNotFound": "Paragem não encontrada",
    "preHeading": "Horários aproximados de chegada do primeiro e último autocarro"
  },
  "agencies": {
    "municipios": "Municípios",
    "cim": "CIM's",
    "privados": "Privados"
  },
  "footer": {
    "allRights": "© QMob Quadrilátero - Todos os direitos reservados.",
    "devBy": "Desenvolvido por"
  }
}
