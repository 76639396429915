import { h } from "preact";
import styled from "styled-components";

export default function ContactForm() {
  return (
    <Wrapper>
      <div class="wrapper">
        <div class="row no-gutters">
          <div class="col-lg-8 col-md-7 order-md-last d-flex align-items-stretch">
            <div class="contact-wrap w-100 p-md-5 p-4">
              <h3 class="mb-4">Entre em contacto!</h3>
              <div id="form-message-warning" class="mb-4"></div>
              {/* <div id="form-message-success" class="mb-4">
                Mensagem enviada, obrigado!
              </div> */}
              <form
                method="POST"
                id="contactForm"
                name="contactForm"
                class="contactForm"
                novalidate="novalidate"
              >
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="label" for="name">
                        Nome
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="name"
                        id="name"
                        placeholder="Nome"
                      />
                      <div
                        data-lastpass-icon-root="true"
                        style="position: relative !important; height: 0px !important; width: 0px !important; float: left !important;"
                      ></div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="label" for="email">
                        Email
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        name="email"
                        id="email"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="label" for="subject">
                        Assunto
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        name="subject"
                        id="subject"
                        placeholder="Assunto"
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="label" for="#">
                        Mensagem
                      </label>
                      <textarea
                        name="message"
                        class="form-control"
                        id="message"
                        cols="30"
                        rows="4"
                        placeholder="Mensagem"
                      ></textarea>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <input
                        type="submit"
                        value="Enviar"
                        class="btn btn-primary"
                      />
                      <div class="submitting"></div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-lg-4 col-md-5 d-flex align-items-stretch">
            <div class="info-wrap bg-primary w-100 p-md-5 p-4">
              <h3>Vamos falar</h3>
              <p class="mb-4">
                Estamos abertos a sugestões ou apenas para falar
              </p>
              {/* <div class="dbox w-100 d-flex align-items-start">
                <div class="icon d-flex align-items-center justify-content-center">
                  <span class="fa fa-map-marker"></span>
                </div>
                <div class="text pl-3">
                  <p>
                    <span>Address:</span> 198 West 21th Street, Suite 721 New
                    York NY 10016
                  </p>
                </div>
              </div>
              <div class="dbox w-100 d-flex align-items-center">
                <div class="icon d-flex align-items-center justify-content-center">
                  <span class="fa fa-phone"></span>
                </div>
                <div class="text pl-3">
                  <p>
                    <span>Phone:</span>{" "}
                    <a href="tel://1234567920">+ 1235 2355 98</a>
                  </p>
                </div>
              </div>
              <div class="dbox w-100 d-flex align-items-center">
                <div class="icon d-flex align-items-center justify-content-center">
                  <span class="fa fa-paper-plane"></span>
                </div>
                <div class="text pl-3">
                  <p>
                    <span>Email:</span>{" "}
                    <a href="mailto:info@yoursite.com">info@yoursite.com</a>
                  </p>
                </div>
              </div>
              <div class="dbox w-100 d-flex align-items-center">
                <div class="icon d-flex align-items-center justify-content-center">
                  <span class="fa fa-globe"></span>
                </div>
                <div class="text pl-3">
                  <p>
                    <span>Website</span> <a href="#">yoursite.com</a>
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);

  .info-wrap {
    color: rgba(255, 255, 255, 0.8);
  }
`;
