import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

// Sentry.init({
//   dsn: 'https://409bfad716f048eda563fd93de156bbd@o476993.ingest.sentry.io/6557784',
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 0.2,
//   environment:
//     location.hostname === 'qmob.pt' ? 'production' : 'development',
//   debug: /localhost/i.test(location.hostname),
// });
