import { Fragment, h } from "preact";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useState } from "preact/hooks";

import logo from "../images/logo_white.png";
import HeaderMenu from "./HeaderMenu";
import LocaleSelector from "./LocaleSelector";
import microLogo from "../images/micro_logo.png";
import cidadesVertical from "../images/citys-logos-vertical.png";

export default function Header() {
  const { t, i18n } = useTranslation();
  const handleClickLogo = () => {
    window.location = "/";
  };
  const [showResponsiveMenu, setShowResponsiveMenu] = useState(false);

  const toggleResponsiveMenu = () => {
    setShowResponsiveMenu(cur => !cur);
  };
  const closeMenu = () => {
    setShowResponsiveMenu(!showResponsiveMenu)
  };
  const _renderResposiveMenu = () => (
    <ReponsiveMenuPopover visible={showResponsiveMenu} className="responsive-menu-popover">
      <MenuPopoverRow>
        <a className="navbar-brand" onClick={handleClickLogo}>
          <img id="logo" src={logo} style={{ height: 60 }} />
        </a>
        <a href="#/" onClick={closeMenu} class="popover-close-mobile">
          <i class="uil uil-times"></i>
        </a>
      </MenuPopoverRow>
      <MenuPopoverRow>
        <ReponsiveMenu>
          <HeaderMenu />
          <LocaleSelector />
        </ReponsiveMenu>
      </MenuPopoverRow>
      <MenuPopoverRow>
        <CitiesLogos>
          <img src={cidadesVertical} style={{ maxWidth: 400 }}/>
        </CitiesLogos>
      </MenuPopoverRow>
      <MenuPopoverRow>
        <div class="col-auto my-auto text-center d-md-block">
          <p class="footer-copy">{t('footer.allRights')}</p>
          <p class="footer-copy-dev">
            {t('footer.devBy')} <a href="https://wegoshare.com/" target="_blank">Wegoshare, Lda.</a><span class="footer-copy-dev-hidden"> v0.2</span>
          </p>
        </div>
      </MenuPopoverRow>
    </ReponsiveMenuPopover>
  );

  return (
    <Fragment>
      <nav class="navbar navbar-dark bg-dark p-3">
        <a className="navbar-brand" onClick={handleClickLogo}>
          <img id="logo" src={logo} style={{ height: 50 }} />
        </a>
        <div class="">
          <HeaderMenu />
        </div>
        <div class="ml-auto">
          <LocaleSelector />
        </div>
      </nav>
      <ResponsiveLogo className="responsive-logo" onClick={toggleResponsiveMenu} />
      {_renderResposiveMenu()}
      {showResponsiveMenu && <AppOverlay onClick={toggleResponsiveMenu} />}
    </Fragment>
  );
}

const ResponsiveLogo = styled.div`
  background-image: url(${microLogo});
  background-position: center;
  background-repeat: no-repeat;
  backdrop-filter: blur(10px) saturate(3);
  background-color: #ffffffb3;
  width: 50px;
  height: 50px;
  position: fixed;
  background-size: 30px 30px;
  border-radius: 50%;
  top: 8px;
  left: 8px;
  box-shadow: 0 0 0 2px #0006 !important;
  display: none;
  cursor: pointer;
  transition: all .3s ease-in-out;

  &:hover{
    background-color: #0000000d;
  }
`;


const ReponsiveMenuPopover = styled.div`
  height: auto;
  transition: all .3s ease-in-out;
  bottom: 0;
  width: calc(100% - 4px);
  left: 2px;
  position: fixed;
  z-index: 101;
  background: #343a40;
  border-radius: 15px 15px 0 0;
  min-height: 300px;
  transform: translateY(100%);

  ${({ visible }) => visible && `
    transform: none;
  `}
`;

const MenuPopoverRow = styled.div`
  margin: 10px;
  padding: 10px;
  border-bottom: 1px solid #6a6a6a;
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    border-bottom: none;
  }
`;

const ReponsiveMenu = styled.div`
  margin-bottom: 18px;

  ul {
    display: grid;
    margin-bottom: 11px;

    li {
      text-align: center;
      padding: 6px 0;
    }
  }

  #locale-selector {
    justify-content: center;
  }
`;

const CitiesLogos = styled.div`
  img {
    width: 100%;
  }
`;

const AppOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: #333;
  opacity: .7;
  top: 0;
  left: 0;
  z-index: 10;
`;
