import { h } from "preact";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useEffect, useRef, useState } from "preact/hooks";

import portugal from "../images/portugal.svg";
import spain from "../images/flag-spain.svg";
import uk from "../images/united-kingdom.svg";
import { LANGUAGE_EN_CODE, LANGUAGE_ES_CODE, LANGUAGE_PT_CODE } from "../utils/constants";

export default function LocaleSelector() {
  const { t, i18n } = useTranslation();
  const valueRef = useRef();
  const optionsRef = useRef();
  const [open, setOpen] = useState();

  const options = [
    { value: LANGUAGE_PT_CODE, label: "Português", icon: portugal },
    { value: LANGUAGE_ES_CODE, label: "Español", icon: spain },
    { value: LANGUAGE_EN_CODE, label: "English", icon: uk },
  ];

  const selectedOption = options.find((o) => o.value === i18n.resolvedLanguage);

  const handleClickOption = o => e => {
    const lang = o.value;
    i18n.changeLanguage(lang);
    setOpen(false);
  }

  const renderOption = (o) => {
    return (
      <Option onClick={handleClickOption(o)}>
        <OptionLabel>{o?.label}</OptionLabel>
        <Flag src={o.icon} />
      </Option>
    )
  }

  const handleClickLabel = () => {
    setOpen(cur => !cur);
  };

  const position = valueRef.current ? valueRef.current?.getBoundingClientRect() : {};

  const top = position.top + position.height + 6;
  const right = (window.innerWidth - position.left) - position.width;

  const handleClickOutside = (event) => {
    if (valueRef.current && valueRef.current.contains(event.target)) {
      return;
    }

    if (optionsRef.current && !optionsRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
        document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <Wrapper  id="locale-selector">
      <CurrentValue ref={valueRef} onClick={handleClickLabel}>
        <Label>{selectedOption?.label}</Label>
        <Flag src={selectedOption.icon} />
        {open ? <ArrowUp /> : <ArrowDown />}
      </CurrentValue>

      {open && (
        <OptionsContainer ref={optionsRef} style={{ top, right }}>
          {options.map(renderOption)}
        </OptionsContainer>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
    display: flex;
    margin: 0 1rem 0 1rem;
    align-items: center;
    height: 100%;
`;

const CurrentValue = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const Label = styled.label`
  color: #fff;
  margin: 0;
  font-size: 14px;
    cursor: pointer;
`;

const Flag = styled.img`
    width: 18px;
    height: auto;
    margin-left: 10px;
`;

const OptionsContainer = styled.div`
    backdrop-filter: blur(10px) saturate(3);
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 0 2px #0000001a;
    position: fixed;
    z-index: 1000;
    padding: 3px;
`;

const Option = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 36px;
  padding: 6px;
  cursor: pointer;

  &:hover {
    background-color: #0000000d;
  }
`;

const OptionLabel = styled.label`
  color: #333;
  margin: 0;
  font-size: 14px;
    cursor: pointer;
`;

const ArrowDown = styled.i`
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 12px;
  width: 10px;
  height: 10px;
  margin-top: -6px;
`;

const ArrowUp = styled.i`
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  margin-left: 12px;
  width: 10px;
  height: 10px;
  margin-top: 0;
`;
