import toast from "react-hot-toast";
import { h } from "preact";
import styled from "styled-components";
import { useState, useRef, useEffect, useMemo } from "preact/hooks";
import { useTranslation } from "react-i18next";

import loadNotifications from "../utils/loadNotifications";
import lowwarning from "../images/low_warning.svg";
import mediumwarning from "../images/medium_warning.svg";
import highwarning from "../images/high_warning.svg";

const mapPriorityIcon = {
  'HIGH': highwarning,
  'MEDIUM': mediumwarning,
  'LOW': lowwarning,
}

export default function StopNotifications({ stopId }) {
  const { t, i18n } = useTranslation();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    loadNotifications(i18n.resolvedLanguage, "priority", "ASC", stopId)
      .then((response) => {
        const { results } = response;

        if (results != undefined) {
          setNotifications(results);
        } else {
          setNotifications([]);
        }
      });
  }, [i18n.resolvedLanguage, stopId]);

  return (
    <Wrapper>
      {notifications.map((not) => (
        <Notification>
          <NotifIcon>
            <img src={mapPriorityIcon[not.priority] || mediumwarning} style={{ maxWidth: 70 }}/>
          </NotifIcon>
          <Text className="my-auto">{not.text}</Text>
        </Notification>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 10px 12px;
`;

const Notification = styled.div`
  display: grid;
  grid-template-columns: 10% 1fr;
  padding: 6px 0;
`;

const NotifIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: auto;
    height: auto;
  }
`;

const Text = styled.div`
  font-size: 12px;
  padding-left: 10px;
`;

